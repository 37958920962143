import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/posts-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Thème du défi : Ecrire un `}<a parentName="em" {...{
          "href": "https://fr.wikipedia.org/wiki/Lipogramme"
        }}>{`lipogramme`}</a></em></p>
    <p>{`Bonjour fidèle lecteur, et merci de lire le Courrier Citoyen. Comme vous ne pouvez l’ignorer, un groupe politique est violemment entré en possession du pouvoir hier. Ils ont pris pour emblème une lettre, celle que nous considérions comme première, plus conséquente que ses soeurs, celle synonyme de bonne note chez nos voisins. En conséquence, une décision fut prise, celle s’interdire d’utiliser cette lettre pour cette chronique.`}</p>
    <p>{`Comment s’est déroulée cette prise de pouvoir ? Les serviteurs de ce groupe virulent ont occupé notre résidence présidentielle qui ne put se défendre décemment. Les troupes rebelles eurent bien plus de ressources que prévu et nos propres unités ne purent lutter bien longtemps. Nos concitoyens ont enduré une nuit difficile où le repos fut presque impossible.`}</p>
    <p>{`Certes, les extrémistes sont présentement en possession du pouvoir politique et de nos précieuses terres, ceci dit, nous vous prions de tenir bon le plus longtemps possible. L’espoir est encore présent et nous devons nous réunir pour décider des consignes qui nous permettront, pour nous et pour notre progéniture, de reprendre ces libertés qui nous sont chères.`}</p>
    <p>{`Conservez vos convictions, ne tolérez nul compromis et exigez les droits que vous estimez essentiels. Ensemble nous pourrons nous relever.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      